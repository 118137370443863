<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <template>
        <div class="d-flex gray-box">
          <div>
            <div>
              <div class="form-inp sm">
                <v-select
                    :items="custItems"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="ASP_NEWCUST_KEY"
                    :readonly="RDOnly"
                >
                  <template v-slot:label>
                    회사구분
                    <v-icon color="#EF9191">mdi-circle-small</v-icon>
                  </template>
                </v-select>
              </div>
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  content-class="calendar-modal"
                  color="#FFF"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dateRangeText"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      outlined
                      hide-details
                      class="form-inp icon-calendar ml-2"
                      append-icon="svg-calendar"
                      label="상담일자"
                  ></v-text-field>
                </template>
                <div class="datepicker-range">
                  <v-date-picker
                      v-model="dates[0]"
                      no-title
                      dark
                      color="#F7AFAE"
                      locale="ko-KR"
                      :first-day-of-week="1"
                      :day-format="mixin_getDate"
                      @change="startDate"
                  ></v-date-picker>
                  <v-date-picker
                      v-model="dates[1]"
                      dark
                      color="#F7AFAE"
                      no-title
                      show-current="false"
                      locale="ko-KR"
                      :first-day-of-week="1"
                      :day-format="mixin_getDate"
                      :min="dates[0]"
                      @change="endDate"
                  ></v-date-picker>
                </div>
                <div class="text-center pt-3 pb-3">
                  <v-btn
                      outlined
                      class="btn-default"
                      @click="menu1 = false"
                  >확인
                  </v-btn>
                </div>
              </v-menu>
              <div class="form-inp ml-2">
                <v-select
                    :items="dropItems"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label="아웃바운드구분"
                    placeholder="선택하세요"
                    v-model="CAM_TY"
                ></v-select>
              </div>
            </div>
            <div class="mt-2">
              <v-text-field
                  class="form-inp full"
                  name="name" label="아웃바운드명" id="id" outlined
                  hide-details
                  v-model="CAM_NM"
              >
              </v-text-field>
            </div>

          </div>

          <div class="ml-auto align-self-center mr-0">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="searchOutbnd">조회</v-btn>
          </div>
        </div>
        <h2 class="tit-h2 d-flex mt-3">아웃바운드 리스트</h2>
        <div class="mt-2">
          <v-data-table
              dense
              height="450px"
              :headers="gridDataHeaders"
              :items="gridDataText"
              item-key="index"
              hide-default-footer
              class="grid-default case03"
              fixed-header
              :items-per-page="30"
              :page.sync="page"
              @page-count="pageCount = $event"
              single-select
              @click:row="rowClick1"
              @dblclick:row="rowClick2"
          >

          </v-data-table>
        </div>
      </template>

      <div class="grid-paging text-center pt-2">
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
        ></v-pagination>
        <p class="caption text-right">
          <strong class="clr-error">{{ gridDataText.length }}</strong
          >건의 통계가 검색되었습니다.
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import {mixin} from "../../mixin/mixin";

export default {
  name : "MENU_M710103P01", //name은 'MENU_' + 파일명 조합
  mixins : [mixin],
  props: {
    p01        : {
      type   : Boolean,
      default: false,
    },
    headerTitle: {
      type   : String,
      default: "제목",
    },
    funObndInfo:{
      type: Object,
      value: {},
    },
  },
  data() {
    return {
      menu1             : false,
      menu2             : false,
      menu3             : false,
      menu4             : false,
      footerHideTitle   : "닫기",
      custItems         : [],
      dropItems         : [{CD:"", CD_NM:"선택"}],
      date              : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2             : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dates             : [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],
      timeStep          : '10:10',
      timeStep2         : '10:10',
      radioGroup        : [
        {
          text: "1차",
        },
        {
          text: "2차",
        },
        {
          text: "3차",
        },
      ],
      radioGroupSelected: "1차",
      gridDataHeaders   : [
        {
          text    : "아웃바운드명",
          value   : "CAM_NM",
          align   : "left",
          sortable: true,
        },
        {text: "시작일자", value: "CAM_BEGIN_DATE", align: "center", sortable: false},
        {
          text    : "종료일자",
          value   : "CAM_EOT_DATE",
          align   : "center",
          sortable: true,
        },
        {
          text    : "완료일자",
          value   : "FISH_DATE",
          align   : "center",
          sortable: true,
        },

      ],
      gridDataText      : [],
      page              : 1,
      pageCount         : 0,
      itemsPerPage      : 5,
      totalVisible      : 10,

      //v-model
      ASP_NEWCUST_KEY : '',
      CAM_TY : '',
      CAM_NM : '',
      CAM_ID : '',

      RDOnly:false,
    }
  },

  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },

    init(){
      this.ASP_NEWCUST_KEY = this.funObndInfo.ASP_CUST_KEY;
      this.CAM_NM = this.funObndInfo.CAM_NM;

      this.searchCamTy();
    },

    //Table row 클릭이벤트
    rowClick1(item, row) {
      if (item != null) {
        this.activeRow(row, true);
      }
    },

    activeRow(row, value) {
      console.log(row);
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    //Table row 클릭이벤트
    rowClick2(e, { item }) {
      this.funObndInfo.CAM_NM = item.CAM_NM;
      this.funObndInfo.CAM_ID = item.CAM_ID;

      this.$emit("selectCamNm", this.funObndInfo);
    },

    async searchCamTy(){
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/code/common/code-book/inqry";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["SERVICE"] = 'code.common.code-book';
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅

      requestData.sendData["GROUP_CD"] = "RE010";

      let response = await this.common_postCall(requestData);
      this.searchCamTyCallback(response);
    },
    searchCamTyCallback(response){

      let cumType = response.RE010

      this.dropItems.push(...cumType);

    },

    async searchOutbnd(){
      this.gridDataText = [];

      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/statistics/phone/outbnd/inqire-popup/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'statistics.phone.outbnd.inqire-popup';
      requestData.headers["TYPE"] = "XML_SERVICE";
      requestData.headers["GRID_ID"] = "divGridObndFindList";
      requestData.headers["MENU_ID"] = "";

      // sendData 세팅

      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["DATE_FROM"] = this.formatDate(this.dates[0]);
      requestData.sendData["DATE_TO"] = this.formatDate(this.dates[1]);
      requestData.sendData["CAM_TY"] = this.CAM_TY;
      requestData.sendData["CAM_NM"] = this.CAM_NM;

      let response = await this.common_postCall(requestData);
      this.searchOutbndCallback(response);
    },
    searchOutbndCallback(response) {
      this.gridDataText = response.DATA;
      this.TOT_COUNT = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY + this.gridDataText[i]["CAM_NM"];
        this.gridDataText[i]["id"] = id;
        this.gridDataText[i]["index"] = idx++;
      }

    },
  },

  mounted() {
    this.custItems = this.$store.getters['userStore/GE_USER_COMPANY'];
    this.init();

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + ' ~ ' + newEndDate;
      // return this.dates.join(' ~ ')
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    computedDateFormatted2() {
      return `${this.date} ${this.timeStep}`
    },

  },

  watch: {
    userInfo() {
      this.init();

      this.ASP_NEWCUST_KEY = this.funObndInfo.ASP_CUST_KEY;
      this.CAM_NM = this.funObndInfo.CAM_NM;
      this.CAM_ID = this.funObndInfo.CAM_ID;
    }
  }
};
</script>

<style></style>
